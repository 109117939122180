import { mapState, mapGetters, mapActions } from 'vuex'

// export const apiUrl = 'https://chatvia-api.dev.novads.co';
export const apiUrl = 'https://api.romeoai.io';
export const authComputed = {
    ...mapState('auth', {
        currentUser: (state) => state.currentUser,
		authLoading: (state) => state.authLoading,
    }),
    ...mapGetters('auth', ['loggedIn']),
}

export const profileComputed = {
	...mapState('profile', {
		userInfo: (state) => state.userInfo,
		adminAllUsersInfo: (state) => state.adminAllUsersInfo,
		loading: (state) => state.loading,
	})
}

export const paymentCardListComputed = {
	...mapState('paymentCardList', {
		paymentCardList: (state) => state.paymentCardList,
		selectedPaymentCard: (state) => state.selectedPaymentCard,
	})
}

export const membershipComputed = {
	...mapState('membership', {
		membershipList: (state) => state.membershipList
	})
}

export const messagesAiComputed = {
	...mapState('messageState', {
		currentRoomMessages: (state) => state.currentRoomMessages,
		activeRoomId:(state) => state.activeRoomId,
		roomsList: (state) => state.roomsList,
		loadingMessages: (state) => state.loadingMessages,
		loadingRoom: (state) => state.loadingRoom,
		loading: (state) => state.loading,
	})
}
export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'sendVerificationEmail', 'sendMagicLink', 'loginWithMagicLink', 'resetPassword', 'handleResetPassword'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

export const profileMethods = mapActions('profile', ['getUserInfo', 'updateUserInfo', 'securePaymentProvider'])

export const messageMethods = mapActions('messageState',['getRoomsList', 'createChatRoom', 'updateChatRoom', 'sendMessage'])

export const paymentCardMethods = mapActions('paymentCardList', ['getPaymentCardsList', 'addCreditCard', 'setSelectedPaymentCard'])

export const membershipMethods = mapActions('membership', ['getMembershipList', 'updateMembership'])

export const pixelsMethods = mapActions('pixels', ['getPixelEvent', 'sendPixelEvent', 'sendPurchasePixelEvent'])
